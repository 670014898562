import React from 'react'


const Loader = () => {

    const [uploadOrDownloadCount, 
        setUploadOrDownloadCount] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setUploadOrDownloadCount(
            (beforeValue) => (beforeValue >= 100 ? 0 
                                : beforeValue + 1));
        }, 30);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <div className="preloader d-flex flex-column justify-content-center align-items-center show">
                <img src={require('../assets/images/logo-white.png')} alt="" className="img-fluid" style={{ "width": "150px" }} />
                <div className="preloader_inner">{uploadOrDownloadCount}%</div>
            </div>
        </>
    )
}

export default Loader

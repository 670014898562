import React, { useEffect, useState } from 'react'
import SigninValidation from '../validations/SigninValidation';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SignupValidation from '../validations/SignupValidation';
import Loader from './Loader';

const Header = () => {
    const userToken = JSON.parse(localStorage.getItem('userToken'))
    const isLoggedin = localStorage.getItem('userToken')
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true);

    const [errors, setErrors] = useState({});
    const [inputValue, setInputValue] = useState({
        email: "",
        password: ""
    });

    const [errors2, setErrors2] = useState({});
    const [inputValue2, setInputValue2] = useState({
        email: "",
        password: "",
        referenceId: ""
    });

    const inputHandler = (e) => {
        const { name, value } = e.target;

        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const formSubmit = async (e) => {
        e.preventDefault();

        const loginData = {
            email: inputValue.email,
            password: inputValue.password
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, loginData)
            const data = await resp.data

            setErrors(SigninValidation(inputValue));


            if (loginData.email && loginData.password) {
                if (data.status == '0') {
                    toast.error(data.message)
                }
            }

            if (data.status == 1) {
                localStorage.setItem('userToken', JSON.stringify(data.token))
                window.location.href = `${process.env.REACT_APP_CUSTOMER_URL}/${data.loginCode}`
            }


        } catch (error) {
            console.log('Error:', error);
        }

    }

    const inputHandlerSignup = (e) => {
        const {name, value} = e.target;
    
        setInputValue2({
          ...inputValue2,
          [name] : value
        })
      }
      
    const formSubmitSignup = async (e) => {
        e.preventDefault();
    
        const signupData = {
            email: inputValue2.email,
            password: inputValue2.password,
            referenceId: inputValue2.referenceId
        }

    
        try {
          const resp = await axios.post(`${process.env.REACT_APP_API_URL}/register`, signupData)
          const data = await resp.data
    
          setErrors2(SignupValidation(inputValue2));
    
    
          if(data.message == 'Email already exists'){
            toast.error(data.message)
          }
    
          if(data.status == 1){
            toast.success(data.message)
            document.getElementById('signupModal').querySelector('.btn-close').click();
            setInputValue2({
                email: "",
                password: "",
                referenceId: ""
            })
          }
          
          
        } catch (error) {
          console.log('Error:', error);
        }
    
    }

    const dashboardFun = () => {
        window.location.href = `${process.env.REACT_APP_CUSTOMER_URL}`
    }

    const loginStatusFun = async () => {
        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-login-status`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await resp.data

            if(data.loggedIn == "no"){
                localStorage.removeItem('userToken')
                navigate('/')
            } 
            
            
        } catch (error) {
            console.log('Error:', error);
        }
    }

    const loadingFun = () => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }

    useEffect(() => {
        if (isLoggedin) {
            loginStatusFun();
        }

        loadingFun();
        
    }, [])

    return (
        <>
            { isLoading && <Loader /> }

            <nav className="navbar navbar1 navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="index.html"><img src={require('../assets/images/logo.png')} alt="" className="img-fluid logo1" /></a>

                    <div className="ms-auto order-lg-2">
                        {
                            isLoggedin ?
                            <div className="links">
                                <Link to="#" className="link" onClick={dashboardFun}>Dashboard</Link>
                            </div>
                            : 
                            <div className="links">
                                <Link href="#" className="link" data-bs-toggle="modal" data-bs-target="#loginModal">Login</Link>
                                <span className="px-2">|</span>
                                <Link className="link" data-bs-toggle="modal" data-bs-target="#signupModal">Signup</Link>
                            </div>
                        }
                    </div>

                    <button className="navbar-toggler ms-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="#">Overview</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Solution</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Service</a>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/pricing' className="nav-link">Pricing</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Business</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Blog</a>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About Us
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="our-projects.html">Our Projects</a></li>
                                    <li><a className="dropdown-item" href="aboutus.html">About Us</a></li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Modal */}
            <div className="modal modal1 fade" id="signupModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ "maxWidth": "580px" }}>
                    <div className="modal-content">
                        <div className="modal-header px-4 pt-4 border-0 pb-0">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4 p-md-5">
                            <h3 className="text-center mb-4">Signup</h3>

                            <form className="form form1" onSubmit={formSubmitSignup}>
                                <div className="mb-3">
                                    <input type="text" className="form-control" placeholder="Email" value={inputValue2.email} name="email" onChange={inputHandlerSignup}/>
                                    {errors2.email && <p className="error-text">{errors2.email}</p>}
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" placeholder="Password" value={inputValue2.password} name="password" onChange={inputHandlerSignup} />
                                    {errors2.password && <p className="error-text">{errors2.password}</p>}
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" placeholder="Refer Code" value={inputValue2.referenceId} name="referenceId" onChange={inputHandlerSignup} />
                                    {errors2.referenceId && <p className="error-text">{errors2.referenceId}</p>}
                                </div>
                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn btn1 btn-black">Signup</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <div className="modal modal1 fade" id="loginModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ "maxWidth": "580px" }}>
                    <div className="modal-content">
                        <div className="modal-header px-4 pt-4 border-0 pb-0">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4 p-md-5">
                            <h3 className="text-center mb-4">Login</h3>

                            <form className="form form1" onSubmit={formSubmit}>
                                <div className="mb-3">
                                    <input type="text" className="form-control" placeholder="Email" name="email" onChange={inputHandler} />
                                    {errors.email && <p className="error-text">{errors.email}</p>}
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={inputHandler} />
                                    {errors.password && <p className="error-text">{errors.password}</p>}
                                </div>
                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn btn1 btn-black">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Header

import React from 'react'
import { FaDiscord, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className="footer-section pb-4 position-relative">
                <div className="container">

                    <div className="footer-info">
                        <div className="row gy-4">
                            <div className="col-md-4 col-12">
                                <div className="align-items-center d-flex">
                                    <div className="icon">
                                        <FaTelegramPlane />
                                    </div>
                                    <div className="txt">
                                        <p className="fw-500 mb-0 text-111">onepayglobalio</p>
                                        <p className="fs-12 mb-0 text-111">Telegram</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="align-items-center d-flex">
                                    <div className="icon">
                                        <FaDiscord />
                                    </div>
                                    <div className="txt">
                                        <p className="fw-500 mb-0 text-111">onepayglobalio</p>
                                        <p className="fs-12 mb-0 text-111">Discord</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="align-items-center d-flex">
                                    <div className="icon">
                                        <FaEnvelope />
                                    </div>
                                    <div className="txt">
                                        <p className="fw-500 mb-0 text-111">support@onepayglobal.io</p>
                                        <p className="fs-12 mb-0 text-111">Email</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row gx-3 gy-4">
                        <div className="col-lg-4 col-12">
                            <img src={require('../assets/images/logo-white.png')} alt="" className="img-fluid logo1 mb-4" />
                                <p className="mb-0 fs-14">Onepay Global: Bridging Borders with Seamless Payments. Empowering Your Business with Innovative Payment Solutions</p>
                                <div className="social mt-4 pt-1">
                                    <Link to="#"><FaFacebookF /></Link>
                                    <Link to="#"><FaTwitter /></Link>
                                    <Link to="#"><FaLinkedinIn /></Link>
                                    <Link to="#"><FaInstagram /></Link>
                                </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 ms-auto">
                            <p className="mb-3 text-main fw-500">Company</p>
                            <ul className="p-0 m-0 list-unstyled">
                                <p className="mb-3 fs-14"><Link to="#" className="link">Solution</Link></p>
                                <p className="mb-3 fs-14"><Link to="#" className="link">Pricing</Link></p>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <p className="mb-3 text-main fw-500">Help</p>
                            <ul className="p-0 m-0 list-unstyled">
                                <p className="mb-3 fs-14"><Link to="#" className="link">Customer Support</Link></p>
                                <p className="mb-3 fs-14"><Link to="#" className="link">Terms & Conditions</Link></p>
                                <p className="mb-3 fs-14"><Link to="#" className="link">Privacy Policy</Link></p>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <p className="mb-3 text-main fw-500">Resources</p>
                            <ul className="p-0 m-0 list-unstyled">
                                <p className="mb-3 fs-14"><Link to="#" className="link">Partnership</Link></p>
                                <p className="mb-3 fs-14"><Link to="#" className="link">Investor</Link></p>
                                <p className="mb-3 fs-14"><Link to="#" className="link">Developer Integration</Link></p>
                            </ul>
                        </div>
                    </div>

                    <hr className="hr1 mt-5 mb-4" />

                    <div className="text-center">
                        <p className="mb-0 fs-14">© 2024 OnePay Global. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer

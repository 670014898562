import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactValidation from '../validations/ContactValidation';
import { toast } from 'react-toastify';
import axios from 'axios';

const Pricing = () => {

    const [errors, setErrors] = useState({});
    const [inputValue, setInputValue] = useState({
        name: "",
        email: "",
        telegramAccount: ""

    });

    const inputHandler = (e) => {
        const { name, value } = e.target;

        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const formSubmit = async (e) => {
        e.preventDefault();

        const loginData = {
            name: inputValue.name,
            email: inputValue.email,
            telegramAccount: inputValue.telegramAccount
        }

        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/save-contact`, loginData)
            const data = await resp.data

            setErrors(ContactValidation(inputValue));
            
            if (loginData.name && loginData.email && loginData.telegramAccount) {
                if (data.status == '0') {
                    toast.error(data.message)
                }
            }

            if (data.status == 1) {
                toast.success(data.message)
                setInputValue({
                    name: "",
                    email: "",
                    telegramAccount: ""
                })
            }


        } catch (error) {
            console.log('Error:', error);
        }

    }

    return (
        <>
            <Header />

            <div className="banner2 padding bg-white">
                <div className="position-relative">
                    <div className='space-top'></div>

                    <div className="container pb-5">
                        <div className="row mb-5">
                            <div className="col-lg-8 col-12 text-center mx-auto">
                                <span className="tag1 d-inline-block">Onepay Global</span>
                                <h1 className="mb-0 fw-500 display-6">Pricing</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mx-auto">
                                <div className='pricing-box bg-white'>
                                    <h3 className='mb-3 text-main'>Fee starting from 1.5%</h3>

                                    <h5>Fees</h5>
                                    <p className='mb-2'>1-10m/month 1.5%</p>
                                    <p className='mb-2'>10-30m/month 1.3%</p>
                                    <p className='mb-2'>30-50m/month 1%</p>
                                    <p className='mb-2'>50+ on request</p>
                                    
                                    <h5 className='mt-4'>Fees Withdrawal</h5>
                                    <p className='mb-2'>THB 0%</p>
                                    <p className='mb-2'>USDT 1%</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Pricing

import React, { useState } from 'react'
import Header from '../components/Header'
import { FaApple, FaArrowRight, FaCreditCard, FaGoogle, FaMoneyBill, FaPlay, FaRegCreditCard, FaWindows } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import { FaCirclePlus } from 'react-icons/fa6'
import { PiCurrencyBtcFill } from 'react-icons/pi'
import ModalVideo from 'react-modal-video';

import { TickerTape } from "react-ts-tradingview-widgets";


const Home = () => {

    const [isOpen, setOpen] = useState(false);
    
    return (
        <>
            <Header />

            <div className="banner padding pb-lg-3 position-relative">
                <div className="position-relative">
                    <div className="container">
                        <div className="row align-items-center gy-5">
                            <div className="col-lg-7 col-xl-8 col-12 pe-lg-5">
                                <h1 className="display-3 fw-500 mb-4">Simplify all your transactions with us.</h1>

                                <p className="mb-4 text-111">The New Web3 Payment Gateway</p>

                                <div>
                                    <Link to="#" className="btn btn1 btn-black me-4" data-bs-toggle="modal" data-bs-target="#signupModal">Register Now</Link>
                                    <Link to="/contact" className="align-middle">Contact Us <span className="ms-2" style={{
                                        "width": "35px",
                                        "height": "35px",
                                        "lineHeight": "32px",
                                        "border": "1px solid #111",
                                        "borderRadius": "50%",
                                        "display": "inline-block",
                                        "textAlign": "center",
                                        "transform": "rotate(-45deg)"
                                    }}><FaArrowRight /></span></Link>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-4 col-12">
                                <img src={require('../assets/images/img1.png')} className="img-fluid w-100" alt="" />
                            </div>
                        </div>

                        <div className="text-center mt-5 pt-lg-5">
                            <p className="mb-2 text-111">Application Coming Soon October</p>
                            <div className="appbtns">
                                <Link to="#" className="btn btn-black btn1 m-1"><FaApple className="me-1 fs-4" /> <span className="align-middle">App Store</span></Link>
                                <Link to="#" className="btn btn1 btn-black m-1"><FaGoogle className="me-1 fs-4" /> <span className="align-middle">Google Pay</span></Link>
                                <Link to="#" className="btn btn1 btn-black m-1"><FaWindows className="me-1 fs-4" /> <span className="align-middle">Private Browser</span></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <img src={require('../assets/images/graphic3.png')} alt="" className="graphic-bottom img-fluid" />
            </div>

            <div className="padding pb-4 pb-lg-4">
                {/* <div className="container">
                    <div className="row d-none d-lg-flex">
                        <div className="col logo-box top">
                            <img src="images/Stripe.png" alt="" className="img-fluid img1"/>
                        </div>
                        <div className="col logo-box top">
                            <img src="images/Visa.png" alt="" className="img-fluid img1"/>
                        </div>
                        <div className="col logo-box">
                            <img src="images/Stripe.png" alt="" className="img-fluid img1"/>
                        </div>
                        <div className="col logo-box">
                            <img src="images/Visa.png" alt="" className="img-fluid img1"/>
                        </div>
                        <div className="col logo-box">
                            <img src="images/Stripe.png" alt="" className="img-fluid img1"/>
                        </div>
                        <div className="col logo-box top">
                            <img src="images/Visa.png" alt="" className="img-fluid img1"/>
                        </div>
                        <div className="col logo-box top">
                            <img src="images/Stripe.png" alt="" className="img-fluid img1"/>
                        </div>
                    </div>

                </div> */}

                {/* TradingView Widget BEGIN */}
                <TickerTape  
                    colorTheme="dark"
                    isTransparent
                ></TickerTape>
                {/* TradingView Widget END */}
            </div>

            <div className="padding bg1 position-relative">
                <img src={require('../assets/images/graphic1.png')} alt="" className="graphic-top img-fluid" />
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-8 col-12 text-center mx-auto">
                            <span className="tag1 d-inline-block">Services</span>
                            <h1 className="text-white mb-3 fw-500 display-6">All In One Solution</h1>
                            <p className="mb-0">Take a look at Services</p>
                        </div>
                    </div>

                    <div className="">
                        <div className="nav justify-content-between nav-tabs1 flex-nowrap scrollbar" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button"
                                role="tab" aria-controls="nav-1" aria-selected="true">
                                <FaCreditCard className='me-1' /> <span className="align-middle">E-Payment</span>
                            </button>
                            <button className="nav-link" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab"
                                aria-controls="nav-2" aria-selected="false">
                                <FaRegCreditCard className='me-1' /> <span className="align-middle">Debit Card</span>
                            </button>
                            <button className="nav-link" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3" type="button" role="tab"
                                aria-controls="nav-3" aria-selected="false">
                                <PiCurrencyBtcFill className='me-1' /> <span className="align-middle">Crypto Payments</span>
                            </button>
                            <button className="nav-link" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-4" type="button" role="tab"
                                aria-controls="nav-4" aria-selected="false">
                                <FaMoneyBill className='me-1' /> <span className="align-middle">P2P Payments</span>
                            </button>
                        </div>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                            <div className="row pt-4 pt-lg-5">
                                <div className="col-xl-10 col-lg-11 mx-auto">
                                    <div className="row">
                                        <div className="col-lg-7 col-12">
                                            <h4 className="text-white mb-3">Onepay Global: Enhancing Business Efficiency with Seamless E-Payment Solutions</h4>
                                            <p>"Onepay Global: Alternative Payment Solutions in Asia - Specializing in Swift Cross-Border Transactions and Crypto Solutions"</p>
                                        </div>
                                    </div>

                                    {/* <div className="row gx-3 gy-4 mt-1">
                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaMoneyBill className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Buy anything anywhere</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaCheckToSlot className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Accept Payment</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaWallet className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Connect your wallet</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaMoneyBill className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Buy anything anywhere</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaCheckToSlot className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Accept Payment</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaWallet className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Connect your wallet</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaMoneyBill className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Buy anything anywhere</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-ms-4 col-sm-6 col-6">
                                            <div className="d-flex">
                                                <div className="me-2" style={{ "width": "20px", "minWidth": "20px" }}>
                                                    <FaCheckToSlot className='fs-6 text-white' />
                                                </div>
                                                <div className="w-100">
                                                    <p className="mb-0 text-white">Accept Payment</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                            <div className="row pt-4 pt-lg-5">
                                <div className="col-xl-10 col-lg-11 mx-auto">
                                    <div className="row">
                                        <div className="col-lg-7 col-12">
                                            <h4 className="text-white mb-3">Utilize Our Debit Card Solution for Easy Access to Your Funds</h4>
                                            <p>Apply our debit card solution for seamless and convenient access to all your funds. Enjoy effortless transactions and financial management with Onepay Global's secure and user-friendly debit card services.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                            <div className="row pt-4 pt-lg-5">
                                <div className="col-xl-10 col-lg-11 mx-auto">
                                    <div className="row">
                                        <div className="col-lg-7 col-12">
                                            <h4 className="text-white mb-3">Real-Time Crypto Payment Solutions</h4>
                                            <p>Our crypto payment solution enables you to convert your money to cryptocurrency or vice versa in real-time. Easily transfer your crypto funds to your ATM debit card for seamless access and transactions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab">
                            <div className="row pt-4 pt-lg-5">
                                <div className="col-xl-10 col-lg-11 mx-auto">
                                    <div className="row">
                                        <div className="col-lg-7 col-12">
                                            <h4 className="text-white mb-3">Peer-to-Peer (P2P) Solutions</h4>
                                            <p>Our P2P solution grants you access to a network of highly reputable vendors, thoroughly screened for your security. This global P2P marketplace allows you to select competitive rates from vendors around the world, ensuring you get the best deals and reliable service.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="padding">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-12 col-12">

                        </div>
                        <div className="col-lg-8 col-12">
                            <h1 className="text-white mb-0 fw-500 display-6">Let's learn how <span className="text-white">OnePay Global</span> work</h1>
                        </div>
                        <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                            <p className="mb-0">Your journey is seem-less, safe & secure.</p>
                        </div>
                    </div>

                    <div className="position-relative video-box">
                        <img src={require('../assets/images/graphic1.png')} alt="" className="graphic-top img-fluid" style={{ "width": "80%", "maxWidth": "550px" }} />
                        <img src={require('../assets/images/img2.png')} alt="" className="img-fluid w-100" style={{ "maxHeight": "680px", "objectFit": "cover" }} />


                        <ModalVideo
                            channel="youtube"
                            youtube={{ mute: 0, autoplay: 0 }}
                            isOpen={isOpen}
                            videoId="z2X2HaTvkl8"
                            onClose={() => setOpen(false)}
                        />
                        <Link onClick={() => setOpen(true)}><div className="icon">
                            <FaPlay />
                        </div></Link>

                        <img src={require('../assets/images/graphic1.png')} alt="" className="graphic-bottom2 img-fluid" style={{ "width": "80%", "maxWidth": "550px" }} />
                    </div>
                </div>
            </div>

            <div className="padding bg1">
                <div className="container mb-5 mb-lg-4 pb-5">
                    <div className="banking-box mb-4">
                        <div className="row gy-4">
                            <div className="align-self-center col-12 col-lg-6">
                                <h1 className="display-6 fw-500 mb-0">Open your account today Worry-free and Easy.</h1>
                                <div className="mt-4 pt-3">
                                    <button className="btn btn1 btn-black">Apply Now</button>
                                </div>
                            </div>
                            <div className="align-self-end col-12 col-lg-2 text-center">
                                <img src={require('../assets/images/img4.png')} alt="" className="img-fluid" style={{ "width": "120px" }} />
                            </div>

                            <div className="align-self-end col-12 col-lg-4">
                                <img src={require('../assets/images/img3.png')} alt="" className="img-fluid w-100 img1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="padding pb-0">
                <div className="container mb-5 mb-lg-4">
                    <div className="align-items-center gy-5 money-row row">
                        <div className="col-12 col-lg-7 col-xl-6">
                            <span className="tag1 d-inline-block">About Us</span>
                            <p className="mb-4">Get to know more about us</p>
                            <h1 className="display-3 fw-500 mb-0 text-white">We take your money matters seriously</h1>

                            <div className="row mt-4 mt-lg-5 gy-4">
                                <div className="col-md-4 col-6">
                                    <h2 className="fw-normal text-main">146k</h2>
                                    <p className="mb-0">Growup users from all over the globe</p>
                                </div>
                                <div className="col-md-4 col-6">
                                    <h2 className="fw-normal text-white">654m</h2>
                                    <p className="mb-0">Total Transaction Processed</p>
                                </div>
                                <div className="col-md-4 col-6">
                                    <h2 className="fw-normal text-white">100%</h2>
                                    <p className="mb-0">Transparency & Sustainability</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 col-xxl-4 ms-auto ps-xl-5">
                            <img src={require('../assets/images/USDT.png')} alt="" className="d-block img img-fluid img1" />
                            <img src={require('../assets/images/btc.png')} alt="" className="d-block img img-fluid img2 ms-auto" />
                            <img src={require('../assets/images/debit.png')} alt="" className="d-block img img-fluid img3" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="padding">
                <hr className="hr1 m-0" />
            </div>

            <div className="padding pt-0">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-8 col-12">
                            <h1 className="text-white mb-0 fw-500 display-6">Onepay Global Principle</h1>
                        </div>
                        <div className="col-lg-4 col-12 mt-4 mt-lg-0">

                        </div>
                    </div>

                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="switching">
                                <div className="d-flex mb-3">
                                    {/* <div className="circle">
                                    <i className="fa-solid fa-circle" style="color: #1f69f1;"></i>
                                </div> */}
                                    <div className="align-self-end w-100">
                                        <h1 className="display-5 fw-500 mb-0 text-white"><span className="align-middle fw-normal text-w60" style={{ "fontSize": "24px" }}>Secure</span></h1>
                                    </div>
                                </div>
                                <p className="mb-0">We are a PCI DSS certified payment gateway, having completed sophisticated penetration tests, and are qualified to provide businesses with a highly secure platform for accepting online payments.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="switching">
                                <div className="d-flex mb-3">
                                    {/* <div className="circle">
                                    <i className="fa-solid fa-circle" style="color: #8226f5;"></i>
                                </div> */}
                                    <div className="align-self-end w-100">
                                        <h1 className="display-5 fw-500 mb-0 text-white"><span className="align-middle fw-normal text-w60" style={{ "fontSize": "24px" }}>24/7 Support</span></h1>
                                    </div>
                                </div>
                                <p className="mb-0">Our professional customer support team consists of individuals who are passionate about client service and accountability. Our team is available 24/7 on business days to assist you.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="switching">
                                <div className="d-flex mb-3">
                                    {/* <div className="circle">
                                    <i className="fa-solid fa-circle" style="color: #f77817;"></i>
                                </div> */}
                                    <div className="align-self-end w-100">
                                        <h1 className="display-5 fw-500 mb-0 text-white"><span className="align-middle fw-normal text-w60" style={{ "fontSize": "24px" }}>Seemless Operations</span></h1>
                                    </div>
                                </div>
                                <p className="mb-0">We provide robust support for all heavy tasks to ensure the smooth operation of your business transactions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="padding bg1">
                <div className="container pb-5">
                    <div className="gy-5 row">
                        <div className="col-12 col-lg-6 col-xl-6 d-flex flex-column justify-content-between pe-md-5 pe-xl-5 align-self-center">
                            <div>
                                {/* <h4 className="fw-normal mb-4 text-white" style={{ "lineHeight": "1.5" }}>Send Money To Your Friend, pay bills and more. Everything is fast and low commission.</h4> */}
                                <h1 className="display-6 fw-500 mb-0 text-white">Send Money To Your Friend, pay bills and more. Everything is fast and low commission.</h1>
                            </div>

                            {/* <div className="blnc">
                                <h1 className="fw-normal mb-3 mb-sm-5 text-white">Total Balance</h1>

                                <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
                                    <div className="me-4 me-xl-5">
                                    <p className="mb-0 text-nowrap">Avg. Yearly <br> Interest Rate</p>
                                    <h4 className="mb-0 text-white">15.75%</h4>
                                </div>
                                    <div>
                                        <div className="inr">
                                            <h2 className="mb-0 text-nowrap">75,387.25 USDT</h2>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="col-12 col-lg-6 col-xl-6 col-xxl-5 ps-xl-5 ms-auto order-lg-1 ps-lg-5">
                            <img src={require('../assets/images/img5.png')} alt="" className="d-block img-fluid w-100" />

                            <div className="mt-3">
                                <Link to="#" className="btn btn1 newcard-btn w-100">Apply Now <FaCirclePlus /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />

        </>
    )
}

export default Home

import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactValidation from '../validations/ContactValidation';
import { toast } from 'react-toastify';
import axios from 'axios';

const Contact = () => {

    const [errors, setErrors] = useState({});
    const [inputValue, setInputValue] = useState({
        name: "",
        email: "",
        telegramAccount: ""

    });

    const inputHandler = (e) => {
        const { name, value } = e.target;

        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const formSubmit = async (e) => {
        e.preventDefault();

        const loginData = {
            name: inputValue.name,
            email: inputValue.email,
            telegramAccount: inputValue.telegramAccount
        }

        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/save-contact`, loginData)
            const data = await resp.data

            setErrors(ContactValidation(inputValue));
            
            if (loginData.name && loginData.email && loginData.telegramAccount) {
                if (data.status == '0') {
                    toast.error(data.message)
                }
            }

            if (data.status == 1) {
                toast.success(data.message)
                setInputValue({
                    name: "",
                    email: "",
                    telegramAccount: ""
                })
            }


        } catch (error) {
            console.log('Error:', error);
        }

    }

    return (
        <>
            <Header />

            <div className="banner2 padding bg-white">
                <div className="position-relative">
                    <div className='space-top'></div>

                    <div className="container pb-5">
                        <div className="row mb-5">
                            <div className="col-lg-8 col-12 text-center mx-auto">
                                <span className="tag1 d-inline-block">Contact</span>
                                <h1 className="mb-0 fw-500 display-6">Contact Us</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-xl-6 col-lg-7 col-md-9 col-12 mx-auto">
                                <div className='contact-box'>
                                    <form className="form form1" onSubmit={formSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" value={inputValue.name} name="name" onChange={inputHandler} />
                                            {errors.name && <p className="error-text">{errors.name}</p>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <input type="text" className="form-control" value={inputValue.email} name="email" onChange={inputHandler} />
                                            {errors.email && <p className="error-text">{errors.email}</p>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Telegram Account</label>
                                            <input type="text" className="form-control" value={inputValue.telegramAccount} name="telegramAccount" onChange={inputHandler} />
                                            {errors.telegramAccount && <p className="error-text">{errors.telegramAccount}</p>}
                                        </div>
                                        <div className="mt-4 text-center">
                                            <button type="submit" className="btn btn1 btn-light">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Contact

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const WidgetDisplay = () => {
    const params = useParams()
    
    const [widgetDetails, setWidgetDetails] = useState({})
    const [paymentType, setPaymentType] = useState('Crypto')
    const [currency, setCurrency] = useState('BTC')

    console.log(widgetDetails);

    const getWidgetDetails = async () => {
        
        if (paymentType == "Crypto") {
            var apiData = {
                code: params.widgetCode,
                paymentType: paymentType,
                currency: currency
            }
        } else {
            var apiData = {
                code: params.widgetCode,
                paymentType: paymentType,
                currency: ''
            }
        }
        
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/widget-details`, apiData)
            const data = await resp.data

            if (data.status == '0') {
                toast.error(data.message)
            }

            if (data.status == 1) {
                // toast.success(data.message)
                setWidgetDetails(data)
            }
            if (data.status == 3) {
                toast.error(data.message)
            }
            if (data.status == 4) {
                toast.error(data.message)
            }


        } catch (error) {
            console.log('Error:', error);
        }
    }

    useEffect(() => {
        getWidgetDetails()
    }, [paymentType, currency])

    return (
        <>
            <div className='payment-page padding'>
                <div className='container'>
                    <div className='text-center mx-auto mb-4' style={{ 'maxWidth': '600px' }}>
                        <img src={require('../assets/images/logo-white.png')} alt="" className="img-fluid" style={{ 'width': '125px' }} />
                    </div>

                    <div className='w-100 mx-auto' style={{ 'maxWidth': '600px' }}>
                        <ul className="nav nav-tabs nav-tabs1 widget-tabs border-0 mb-2 flex-nowrap" id="myTab" role="tablist">
                            <li className="nav-item w-100" role="presentation">
                                <button className="nav-link active" id="one-tab" data-bs-toggle="tab" data-bs-target="#one" type="button" role="tab" aria-controls="one" aria-selected="true" onClick={() => setPaymentType('Crypto')}>Crypto</button>
                            </li>
                            {/* <li className="nav-item w-100" role="presentation">
                                <button className="nav-link" id="two-tab" data-bs-toggle="tab" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="false">Credit Card</button>
                            </li> */}
                            <li className="nav-item w-100" role="presentation">
                                <button className="nav-link" id="three-tab" data-bs-toggle="tab" data-bs-target="#three" type="button" role="tab" aria-controls="three" aria-selected="false" onClick={() => setPaymentType('Bank Transfer')}>Bank</button>
                            </li>
                        </ul>
                    </div>
                    

                    <div className='text-center mx-auto bg1 rounded p-4 p-lg-5 text-white' style={{ 'maxWidth': '600px' }}>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                                
                                <div className='d-inline-block mb-4'>
                                    <ul className="nav nav-tabs nav-tabs1 widget-tabs border-0 mb-2 flex-nowrap" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link py-2 ${currency == 'BTC' && 'active'}`} onClick={() => setCurrency('BTC')}>BTC</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link py-2 ${currency == 'USDT' && 'active'}`} onClick={() => setCurrency('USDT')}>USDT</button>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className='mb-4'>
                                    <h5>Scan the Code</h5>
                                    <img src={`${process.env.REACT_APP_IMAGES_URL}/${widgetDetails.QRImage}`} alt="" className='img-fluid d-block mx-auto' style={{ "width": "120px", "minWidth": "90px" }} />
                                </div>
                                <div className='mb-3'>
                                    <p className='mb-1'>Wallet Address:</p>
                                    <h5 className='text-break'>{widgetDetails.walletAddress}</h5>
                                </div>
                                <div className='mt-4'>
                                    <button className='btn btn1'>Pay</button>
                                </div>
                            </div>

                            {/* <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <form action="" className='form form2'>
                                    <div className='mb-3 text-start'>
                                        <label className='form-label text-white'>Credit Card Number</label>
                                        <div className="mb-3">
                                            <input type="text" className="form-control" placeholder="" name="" />
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='row gx-2 align-items-center text-start'>
                                            <div className='col-sm-8 col-12'>
                                                <label className='form-label text-white'>Exp. Date</label>
                                                <div className='row gx-1 align-items-center'>
                                                    <div className='col-sm-4 col-4'>
                                                        <select className="form-select" aria-label="Default select" name="">
                                                            <option selected disabled>Date</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-sm-4 col-4'>
                                                        <select className="form-select" aria-label="Default select" name="">
                                                            <option selected disabled>Month</option>
                                                            <option value="">Jun</option>
                                                            <option value="">Feb</option>
                                                            <option value="">Mar</option>
                                                            <option value="">Apr</option>
                                                            <option value="">May</option>
                                                            <option value="">Jun</option>
                                                            <option value="">Jul</option>
                                                            <option value="">Aug</option>
                                                            <option value="">Sep</option>
                                                            <option value="">Oct</option>
                                                            <option value="">Nov</option>
                                                            <option value="">Dec</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-sm-4 col-4'>
                                                        <select className="form-select" aria-label="Default select" name="">
                                                            <option selected disabled>Year</option>
                                                            <option value="">2024</option>
                                                            <option value="">2025</option>
                                                            <option value="">2026</option>
                                                            <option value="">2027</option>
                                                            <option value="">2028</option>
                                                            <option value="">2029</option>
                                                            <option value="">2030</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-12'>
                                                <label className='form-label text-white'>CVV</label>
                                                <input type="text" className="form-control" placeholder="" name="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <button className='btn btn1'>Pay</button>
                                    </div>
                                </form>
                            </div> */}

                            <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                                <div className='mb-3'>
                                    <p className='mb-1'>Bank Name</p>
                                    <h5 className=''>{widgetDetails.bankName}</h5>
                                </div>
                                <div className='mb-3'>
                                    <p className='mb-1'>Bank Account Number</p>
                                    <h5 className='text-break'>{widgetDetails.bankAccountNumber}</h5>
                                </div>
                                <div className='mb-3'>
                                    <p className='mb-1'>Bank Account Name</p>
                                    <h5 className=''>{widgetDetails.bankAccountName}</h5>
                                </div>
                                <div className='mt-4'>
                                    <button className='btn btn1'>Pay</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* {
                        paymentDetails && paymentDetails.paymentType == "Bank Transfer" && <div className='text-center mx-auto bg1 rounded p-4 p-lg-5 text-white' style={{ 'maxWidth': '600px' }}>
                            <div className='mb-3'>
                                <p className='mb-1'>Bank Name:</p>
                                <h5>{paymentDetails.bankName}</h5>
                            </div>

                            <div className='mb-3'>
                                <p className='mb-1'>Bank Account Number:</p>
                                <h5>{paymentDetails.bankAccountNumber}</h5>
                            </div>

                            <div className='mb-3'>
                                <p className='mb-1'>Bank Account Name:</p>
                                <h5>{paymentDetails.bankAccountName}</h5>
                            </div>

                            <div className='mt-4'>
                                <button className='btn btn1'>Pay</button>
                            </div>
                        </div>
                    } */}

                </div>
            </div>
        </>
    )
}

export default WidgetDisplay

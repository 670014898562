const ContactValidation = (value) => {
    const errors = {}  
    const regEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    if (!value.name) {
        errors.name = "Name is required"
    }

    if (!value.email) {
        errors.email = "Email is required"
    }
    else if(!regEmail.test(value.email)) {
        errors.email = "Enter valid email"
    }

    if (!value.telegramAccount) {
        errors.telegramAccount = "Telegram Account is required"
    }

    return errors;
}

export default ContactValidation
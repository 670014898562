import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';

const PaymentPage = () => {
    const params = useParams()

    const [paymentDetails, setPaymentDetails] = useState({})
    const [wait, setWait] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState({})
    const [paymentCheckStatus, setPaymentCheckStatus] = useState({})
    const [paymentSuccessful, setPaymentSuccessful] = useState(false)

    
    const [proofImage, setProofImage] = useState("")
    const imageInputRef = useRef();

    const fileHandler = (event) => {
        const file = event.target.files[0];
        setProofImage(file)
    };
    
    const getPaymentLinksDetails = async () => {
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/payment-link-details`, {code: params.paymentCode})
            const data = await resp.data

            if (data.status == '0') {
                toast.error(data.message)
            }

            if (data.status == 1) {
                setPaymentDetails(data)
                // toast.success(data.message)
            }


        } catch (error) {
            console.log('Error:', error);
        }
    }

    const [errors, setErrors] = useState({});
    const validate = () => {
        let errors = {};

        if (proofImage === "" || proofImage == undefined || proofImage == null) {
            
        } else {
            if (!proofImage.name.match(/\.(jpg|jpeg|png|svg|webp)$/)) {
                errors.proofImage = "select valid image."
            }
        }
    
        return errors;
    };

    const payFun = async () => {
        
        const formErrors = validate();
        setErrors(formErrors);
        
        const apiData = new FormData()

        apiData.append('file', proofImage)
        apiData.append('paymentCode', paymentDetails.paymentCode)
        apiData.append('status', "completed")
        
        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/save-link-transaction`, apiData)
                const data = await resp.data

                if (data.status == '0') {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    setPaymentStatus(data.post)
                    toast.success(data.message)
                    setWait(true)
                }
                if (data.status == 3) {
                    toast.success("Payment already done for the link")
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }
    } 

    const getPaymentLinksStatus = async () => {
        const details = {
            paymentCode: paymentDetails.paymentCode
        }
        
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/link-transaction-status`, details)
            const data = await resp.data

            if (data.status == '0') {
                toast.error(data.message)
            }

            if (data.status == 1) {
                setPaymentCheckStatus(data.post)
            }
            if (data.record.accepted == "yes") {
                setPaymentCheckStatus(data.post)
                setPaymentSuccessful(true)
                setWait(false)
            }


        } catch (error) {
            console.log('Error:', error);
        }
    } 

    useEffect(() => {
        getPaymentLinksDetails()
    }, [])

    setInterval(() => {
        if (paymentStatus && paymentStatus.status == "completed") {
            getPaymentLinksStatus()
        }
    }, 5000)


    return (
        <>
            <div className='payment-page padding'>
                <div className='container'>
                    <div className='text-center mx-auto mb-4' style={{'maxWidth':'600px'}}>
                        <img src={require('../assets/images/logo-white.png')} alt="" className="img-fluid" style={{'width':'125px'}}/>
                    </div>

                    {
                        paymentSuccessful ?  <div className='text-center mx-auto bg1 rounded p-4 p-lg-5 text-white' style={{'maxWidth':'600px'}}>
                            <FaRegCheckCircle className='display-3 text-success mb-3' />
                            <h4 className='fw-semibold mb-4'>Payment Successful</h4>
                            <Link to="/" className='btn btn1' onClick={payFun}>Back to Home</Link>
                        </div>
                        : <>
                            {
                            paymentDetails && paymentDetails.paymentType == "Crypto" && <div className='text-center mx-auto bg1 rounded p-4 p-lg-5 text-white' style={{'maxWidth':'600px'}}>
                                <div className='mb-4'>
                                    <h5>Scan the Code</h5>
                                    <img src={`${process.env.REACT_APP_IMAGES_URL}/${paymentDetails.QRImage}`} alt="" className="img-fluid" style={{'width':'110px'}}/>
                                </div>
                                <div className='mb-3'>
                                    <p className='mb-1'>Wallet Address:</p>
                                    <h5 className='text-break'>{paymentDetails.walletAddress}</h5>
                                </div>

                                <div className='mb-3'>
                                    <p className='mb-1'>Currency:</p>
                                    <h5>{paymentDetails.currency}</h5>
                                </div>

                                {
                                    paymentDetails.currency == "USDT" && <div className='mb-3'>
                                        <p className='mb-1'>Network:</p>
                                        <h5>{paymentDetails.network}</h5>
                                    </div>
                                }

                                <div className='mb-3'>
                                    <p className='mb-1'>Amount:</p>
                                    <h5>{paymentDetails.price}</h5>
                                </div>

                                <div className='form form2'>
                                    <div className='mb-3'>
                                        <p className='mb-1'>Payment Transaction Proof: <span className="small text-white">(Not Required)</span></p>
                                        <div className="icon-box">
                                            <input type="file" className="form-control ps-2 mx-auto" accept='image/*' ref={imageInputRef} name="qrImage" onChange={fileHandler} style={{"maxWidth": "380px"}} />
                                            {errors.proofImage && <p className="error-text">{errors.proofImage}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    {
                                        !wait ? <button className='btn btn1' onClick={payFun}>Pay</button> 
                                        : <button className='btn btn1' disabled onClick={payFun}>Please wait...</button>
                                    }
                                    
                                    {
                                        wait && <h5 className='mb-0 mt-4 text-danger'>Please don't close the browser</h5>
                                    }
                                    
                                </div>
                            </div>
                        }

                        {
                            paymentDetails && paymentDetails.paymentType == "Bank Transfer" && <div className='text-center mx-auto bg1 rounded p-4 p-lg-5 text-white' style={{'maxWidth':'600px'}}>
                                <div className='mb-3'>
                                    <p className='mb-1'>Bank Name:</p>
                                    <h5>{paymentDetails.bankName}</h5>
                                </div>

                                <div className='mb-3'>
                                    <p className='mb-1'>Bank Account Number:</p>
                                    <h5>{paymentDetails.bankAccountNumber}</h5>
                                </div>

                                <div className='mb-3'>
                                    <p className='mb-1'>Bank Account Name:</p>
                                    <h5>{paymentDetails.bankAccountName}</h5>
                                </div>

                                <div className='mb-3'>
                                    <p className='mb-1'>Amount:</p>
                                    <h5>{paymentDetails.price}</h5>
                                </div>

                                <div className='form form2'>
                                    <div className='mb-3'>
                                        <p className='mb-1'>Payment Transaction Proof: <span className="small text-white">(Not Required)</span></p>
                                        <div className="icon-box">
                                            <input type="file" className="form-control ps-2 mx-auto" accept='image/*' ref={imageInputRef} name="qrImage" onChange={fileHandler} style={{"maxWidth": "380px"}} />
                                            {errors.proofImage && <p className="error-text">{errors.proofImage}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    {
                                        !wait ? <button className='btn btn1' onClick={payFun}>Pay</button> 
                                        : <button className='btn btn1' disabled onClick={payFun}>Please wait...</button>
                                    }
                                    
                                    {
                                        wait && <h5 className='mb-0 mt-4 text-danger'>Please don't close the browser</h5>
                                    }
                                    
                                </div>
                            </div>
                        }
                        </>
                    }

                </div>
            </div>
        </>
    )
}

export default PaymentPage

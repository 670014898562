import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import './assets/css/style.css'
import './assets/css/responsive.css'

import 'react-toastify/dist/ReactToastify.css';

import 'react-modal-video/css/modal-video.min.css';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Home from './views/Home'
import { ToastContainer } from 'react-toastify'
import Contact from './views/Contact'
import PaymentPage from './views/PaymentPage'
import WidgetDisplay from './views/WidgetDisplay'
import Pricing from './views/Pricing'


function App() {
  return (
    <>
      <Router basename='/'>

        <Routes>
          <Route exect path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/paymentPage/:paymentCode" element={<PaymentPage />} />
          <Route path="/widgetDisplay/:widgetCode" element={<WidgetDisplay />} />
          <Route path="*" element={<Home />} />
        </Routes>

      </Router>

      <ToastContainer position="bottom-center" autoClose={2000} />
    </>
  );
}

export default App;
